import { SignatureConfirmation } from '@@api/signaturesApi';
import {
  SignatureFormGrid,
  useSignatureForm,
} from '@@components/SignatureForm';
import * as UI from '@@ui';
import React from 'react';

/**
 * Provides a context for opening a confirmation dialog via a custom hook.
 * Must be placed inside the ThemeProvider
 */

export type SignedConfirmationOptions = {
  title?: React.ReactNode;
  message?: React.ReactNode;
  canBypass?: boolean;
  confirmLabel?: string;
  cancelLabel?: string;
  onConfirm?: (data: SignatureConfirmation) => any;
  onCancel?: () => any;
};

type SignedConfirmationContextValue = {
  open: (options: SignedConfirmationOptions) => void;
};

const SignedConfirmationContext = React.createContext(
  {} as SignedConfirmationContextValue
);

export const useSignedConfirmation = () =>
  React.useContext(SignedConfirmationContext);

export const SignedConfirmationProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [signatureBypassed, setSignatureBypassed] = React.useState<boolean>();
  const [options, setOptions] = React.useState<SignedConfirmationOptions>();
  const modal = UI.useDisclosure({
    onClose: () => {
      setRunning(false);
      form.reset();
      setSignatureBypassed(undefined);
      options?.onCancel?.();
    },
  });
  const [running, setRunning] = React.useState(false);

  const open = (options: SignedConfirmationOptions) => {
    setOptions(options);
    modal.onOpen();
  };

  const form = useSignatureForm(async (data) => {
    setRunning(true);
    try {
      await options?.onConfirm?.({ signatureMetadata: data });
    } catch (error) {
      setRunning(false);
      throw error;
    }
    modal.onClose();
  });
  const handleBypassClick = async () => {
    setRunning(true);
    try {
      await options?.onConfirm?.({ signatureBypassed: true });
    } catch (error) {
      setRunning(false);
      throw error;
    }
    modal.onClose();
  };
  const handleCaptureClick = () => {
    setSignatureBypassed(false);
  };

  return (
    <SignedConfirmationContext.Provider value={{ open }}>
      {children}
      <UI.Modal closeOnOverlayClick={false} {...modal}>
        <UI.ModalOverlay />
        <UI.ModalContent textAlign="center">
          {!options?.canBypass || signatureBypassed === false ? (
            <UI.Form form={form}>
              <UI.ModalHeader>
                {options?.title || 'Please Sign to Confirm'}
                <UI.ModalCloseButton />
              </UI.ModalHeader>
              <UI.ModalBody>
                {options?.message ? <UI.Text>{options.message}</UI.Text> : null}
                <SignatureFormGrid />
                <UI.FormErrorAlertMessage form={form} />
              </UI.ModalBody>
              <UI.ModalFooter justifyContent="center">
                <UI.ButtonGroup isDisabled={running}>
                  <UI.Button variant="secondary" onClick={modal.onClose}>
                    {options?.cancelLabel || 'Cancel'}
                  </UI.Button>
                  <UI.SubmitButton>
                    {options?.confirmLabel || 'Confirm'}
                  </UI.SubmitButton>
                </UI.ButtonGroup>
              </UI.ModalFooter>
            </UI.Form>
          ) : (
            <React.Fragment>
              <UI.ModalHeader>
                Capture Signature
                <UI.ModalCloseButton />
              </UI.ModalHeader>
              <UI.ModalBody>
                This process requires a signature, but your staff role allows
                you to skip this step if you understand that a signature will
                need to be captured later.
              </UI.ModalBody>
              <UI.ModalFooter justifyContent="center">
                <UI.ButtonGroup isDisabled={running}>
                  <UI.Button variant="secondary" onClick={handleBypassClick}>
                    I Understand, Skip
                  </UI.Button>
                  <UI.Button variant="primary" onClick={handleCaptureClick}>
                    Capture Signature
                  </UI.Button>
                </UI.ButtonGroup>
              </UI.ModalFooter>
            </React.Fragment>
          )}
        </UI.ModalContent>
      </UI.Modal>
    </SignedConfirmationContext.Provider>
  );
};
